
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <form
            class="needs-validation"
            @submit.prevent="userForm"
            @reset="onReset"
          >
            <div id="addproduct-accordion" class="custom-accordion">
              <div class="card">
                <a
                  href="javascript: void(0);"
                  class="text-dark"
                  data-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="addproduct-billinginfo-collapse"
                  v-b-toggle.accordion-1
                >
                  <div class="p-4">
                    <div class="media align-items-center">
                      <div class="me-3">
                        <div class="avatar-xs">
                          <div
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft-primary
                              text-primary
                            "
                          >
                            01
                          </div>
                        </div>
                      </div>
                      <div class="media-body overflow-hidden">
                        <h5 class="font-size-16 mb-1">ข้อมูลทั่วไป</h5>
                      </div>
                      <i
                        class="mdi mdi-chevron-up accor-down-icon font-size-24"
                      ></i>
                    </div>
                  </div>
                </a>

                <b-collapse
                  data-parent="#addproduct-accordion"
                  id="accordion-1"
                  visible
                  accordion="my-accordion"
                >
                  <div class="p-4 border-top">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="mb-3 position-relative">
                          <label for="prefixId">คำนำหน้า:</label>
                          <multiselect
                            id="prefixId"
                            v-model="userform.prefixId"
                            :options="prefixOPtion"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="name">ชื่อ:</label>
                          <input
                            id="name"
                            v-model="userform.name"
                            type="text"
                            class="form-control"
                            value=""
                            :class="{
                              'is-invalid':
                                submitform && $v.userform.name.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.userform.name.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.userform.name.required">{{
                              message
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <label for="familyName">นามสกุล:</label>
                          <input
                            id="familyName"
                            v-model="userform.familyName"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3 position-relative">
                          <label for="gender">เพศ:</label>
                          <multiselect
                            id="gender"
                            v-model="userform.gender"
                            :options="options"
                            track-by="text"
                            label="text"
                            :show-labels="false"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <!-- <code>* </code > -->
                          <code>* </code
                          ><label for="citizenId">เลขบัตรประชาชน:</label>
                          <input
                            id="citizenId"
                            v-model="userform.citizenId"
                            maxLength="13"
                            type="text"
                            class="form-control input-placeholder"
                            :class="{
                              'is-invalid':
                                submitform && $v.userform.citizenId.$error,
                            }"
                            placeholder="รหัสบัตรประชาชน 13 หลัก"
                          />

                          <div
                            v-if="submitform && $v.userform.citizenId.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.userform.citizenId.required ||
                                !$v.userform.citizenId.numeric ||
                                !$v.userform.citizenId.maxLength ||
                                !$v.userform.citizenId.minLength
                              "
                              >{{ message }}
                            </span>
                          </div>
                          <!-- <div v-else-if="submitform" class="invalid-tooltip">
                            <span v-if="this.checks === false">{{ err }} </span>
                            <span v-else-if="this.checks !== false"
                              >เลขบัตรประชาชนถูกต้อง</span
                            >
                          </div> -->
                        </div>
                      </div>

                      <div class="col-sm-4">
                        <div class="mb-3 position-relative">
                          <label for="birthDate">วันเกิด:</label>
                          <!-- disabled="disabled"  -->
                          <date-picker
                            v-model="userform.birthDate"
                            :first-day-of-week="1"
                            format="YYYY-MM-DD"
                            value-type="format"
                            lang="en"
                          ></date-picker>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <label for="mobilePhone">โทรศัพท์:</label>
                          <input
                            id="mobilePhone"
                            v-model="userform.mobilePhone"
                            type="tel"
                            class="form-control"
                            value=""
                            :maxLength="10"
                            :class="{
                              'is-invalid':
                                submitform && $v.userform.mobilePhone.$error,
                            }"
                            placeholder=""
                          />

                          <div
                            v-if="submitform && $v.userform.mobilePhone.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.userform.mobilePhone.numeric ||
                                !$v.userform.mobilePhone.maxLength ||
                                !$v.userform.mobilePhone.minLength
                              "
                              >{{ message }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="mb-3 position-relative">
                          <label for="buildingNumber">บ้านเลขที่:</label>
                          <input
                            id="buildingNumber"
                            v-model="userform.buildingNumber"
                            type="text"
                            class="form-control"
                            value=""
                          />
                          <span class="text-muted"
                            ><strong><code>999/999</code></strong></span
                          >
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3 position-relative">
                          <label for="townshipNumber">หมู่:</label>
                          <input
                            :maxLength="10"
                            :minlength="1"
                            id="townshipNumber"
                            v-model="userform.townshipNumber"
                            type="text"
                            class="form-control"
                            value=""
                          />
                          <span class="text-muted"
                            ><strong><code>ตัวอย่าง 1,2,3</code></strong></span
                          >
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <label for="townshipName">ชื่อหมู่บ้าน:</label>
                          <input
                            id="townshipName"
                            v-model="userform.townshipName"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3 position-relative">
                          <label for="subLane">ตรอก:</label>
                          <input
                            id="subLane"
                            v-model="userform.subLane"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="lane">ซอย:</label>
                          <input
                            id="lane"
                            v-model="userform.lane"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="streetName">ถนน:</label>
                          <input
                            id="streetName"
                            v-model="userform.streetName"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="provinceId">จังหวัด:</label>
                          <multiselect
                            @input="getAmphures(provinceId)"
                            id="provinceId"
                            v-model="provinceId"
                            :options="provinces"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="amphurId">อำเภอ:</label>
                          <multiselect
                            @input="getDistricts(amphurId)"
                            id="amphurId"
                            v-model="amphurId"
                            :options="amphures"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          ></multiselect>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="districtId">ตำบล:</label>
                          <multiselect
                            id="districtId"
                            v-model="districtId"
                            :options="districts"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="postCode">รหัสไปรษณีย์:</label>
                          <input
                            id="postCode"
                            v-model="userform.postCode"
                            :maxLength="5"
                            type="number"
                            class="form-control"
                            value=""
                            :class="{
                              'is-invalid':
                                submitform && $v.userform.postCode.$error,
                            }"
                          />

                          <div
                            v-if="submitform && $v.userform.postCode.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.userform.postCode.numeric ||
                                !$v.userform.postCode.minLength ||
                                !$v.userform.postCode.maxLength
                              "
                              >{{ message }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="fullAddress">ที่อยู่เต็ม:</label>
                          <!-- disabled="disabled"  -->
                          <textarea
                            disabled
                            v-model="fullAddress"
                            class="form-control"
                            name="textarea"
                            rows="5"
                          ></textarea>
                          <p></p>
                        </div>
                      </div>

                      <div class="col-sm-3">
                        <div class="mb-3 position-relative">
                          <label for="startWorkingDate">วันเริ่มงาน:</label>

                          <date-picker
                            disabled
                            v-model="userform.startWorkingDate"
                            format="YYYY-MM-DD"
                            value-type="format"
                          ></date-picker>
                        </div>
                      </div>

                      <div class="row mt-4">
                        <p class="card-title-desc">ข้อมูลการเข้าสู่ระบบ</p>
                        <hr />

                        <div class="col-md-3">
                          <div class="mb-3 position-relative">
                            <code>* </code><label for="email">อีเมล:</label>
                            <input
                              disabled
                              id="email"
                              v-model="userform.email"
                              type="email"
                              class="form-control input-placeholder"
                              value=""
                              :class="{
                                'is-invalid':
                                  submitform && $v.userform.email.$error,
                              }"
                              placeholder="example@gmail.com"
                            />
                            <div
                              v-if="submitform && $v.userform.email.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.userform.email.required">{{
                                message
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-3 position-relative">
                            <label for="password">รหัสผ่าน:</label>
                            <div class="inner-addon right-addon input-group">
                              <input
                                v-if="showPassword"
                                type="text"
                                v-model="userform.password"
                                class="form-control"
                              />
                              <input
                                v-else
                                type="password"
                                v-model="userform.password"
                                class="form-control"
                              />
                              <a @click="toggleShow" class="btn btn-outline-primary">
                                <i
                                  class="fas glyphicon"
                                  :class="{
                                    'uil-eye ': showPassword,
                                    'uil-eye-slash': !showPassword,
                                  }"
                                ></i>
                              </a>
                            </div>
                            <span class="text-muted"
                              ><code
                                >***กรณีเปลี่ยนรหัสผ่าน(อย่างน้อย 8 ตัว)</code
                              ></span
                            >
                            <div
                              v-if="submitform && $v.userform.password.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.userform.password.minLength">{{
                                message
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>

              <!-- <div class="card">
                <a
                  href="javascript: void(0);"
                  class="text-dark collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="addproduct-img-collapse"
                  v-b-toggle.accordion-2
                >
                  <div class="p-4">
                    <div class="media align-items-center">
                      <div class="me-3">
                        <div class="avatar-xs">
                          <div
                            class="
                              avatar-title
                              rounded-circle
                              bg-soft-primary
                              text-primary
                            "
                          >
                            02
                          </div>
                        </div>
                      </div>
                      <div class="media-body overflow-hidden">
                        <h5 class="font-size-16 mb-1">การเข้าใช้งาน</h5>
                      </div>
                      <i
                        class="mdi mdi-chevron-up accor-down-icon font-size-24"
                      ></i>
                    </div>
                  </div>
                </a>

                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  data-parent="#addproduct-accordion"
                >
                  <div class="p-4 border-top">
                    <p class="card-title-desc">สิทธิ์การเข้าใช้งานสาขา</p>
                    <div class="row g-5">
                      <div class="col-lg-12 col-12">
                        <div
                          class="row align-items-center"
                          style="margin-left: 3rem"
                        >
                          <div
                            class="col-md-4 col-lg-3 m-1"
                            v-for="(branchItem, index) in localDataBranchUser"
                            :key="index"
                          >
                            <b-form-checkbox
                              disabled
                              v-model="accessBranchId"
                              :value="branchItem.branchId"
                              class="mb-2 me-2"
                              plain
                              checked
                              :class="{
                                'is-invalid': submitform,
                              }"
                            >
                              {{ branchItem.nameTh }}</b-form-checkbox
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col">
                        <p class="card-title-desc">สิทธิ์การเข้าใช้งานระบบ</p>
                        <hr />

                        <div class="row g-5">
                          <div class="col-12">
                          
                            <div
                              class="col-md-4 col-md-offset-4"
                              style="margin-left: 3rem"
                              v-for="(programItem, index) in rowProgram"
                              :key="index"
                            >
                              <div class="row">
                                <div v-if="programItem.lvl == 1">
                                  <b-form-checkbox
                                    disabled
                                    v-model="accessProgramId"
                                    class="mb-3"
                                    :value="programItem.programId"
                                    plain
                                    checked
                                    :class="{
                                      'is-invalid': submitform,
                                    }"
                                  >
                                    <b>{{ programItem.nameTh }}</b>
                                  </b-form-checkbox>
                                </div>
                                <div v-if="programItem.lvl == 2">
                                  <b-form-checkbox
                                    disabled
                                    v-model="accessProgramId"
                                    class="mb-3 ml-2"
                                    :value="programItem.programId"
                                    plain
                                    checked
                                    :class="{
                                      'is-invalid': submitform,
                                    }"
                                  >
                                    {{ programItem.nameTh }}
                                  </b-form-checkbox>
                                </div>
                                <div v-if="programItem.lvl == 3">
                                  <b-form-checkbox
                                    disabled
                                    v-model="accessProgramId"
                                    class="mb-3 ml-3"
                                    :value="programItem.programId"
                                    plain
                                    checked
                                    :class="{
                                      'is-invalid': submitform,
                                    }"
                                  >
                                    {{ programItem.nameTh }}
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div> -->
            </div>

            <div class="row mb-4">
              <div class="col text-end ms-1">
                <button type="reset" class="btn btn-warning" @click="onReset()">
                  <i class="uil-refresh me-1"></i> ล้างข้อมูล
                </button>
                <button type="submit" class="btn btn-success ms-1">
                  <i class="uil uil-file-alt me-1"></i> บันทึก
                </button>
              </div>
            </div>
          </form>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import {
  required,
  numeric,
  maxLength,
  minLength,
  // maxValue,
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "โปรไฟล์",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },

  data() {
    return {
      loading: undefined,

      title: "แก้ไขโปรไฟล์",
      items: [
        {
          text: "แก้ไขโปรไฟล์",
          active: true,
        },
      ],
      checks: "",
      rowBranch: [],
      rowProgramItem: [],
      rowProgram: [],
      err: "รหัสบัตรประชาชนไม่ถูกต้อง",
      message: "โปรดตรวจสอบข้อมูล",
      userId: this.$route.params.userId, //ดึง id แก้ไข
      selectMode: "multi",
      selected: [],
      isLoading: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowUser: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,

      check: false,
      branchId: [],
      optionsRole: [],
      provinces: [],
      amphures: [],
      districts: [],
      prefixOPtion: [],
      districtId: { nameTh: "" },
      amphurId: { nameTh: "" },
      provinceId: { nameTh: "" },
      options: [
        {
          text: "ชาย",
          id: "M",
        },
        {
          text: "หญิง",
          id: "F",
        },
      ],
      prefixId: "",
      name: "",
      familyName: "",
      gender: "",
      citizenId: "",
      birthDate: "",
      mobilePhone: "",
      email: "",
      buildingNumber: "",
      townshipNumber: "",
      townshipName: "",
      subLane: "",
      lane: "",
      streetName: "",
      postCode: "",
      startWorkingDate: "",
      roleId: "",
      username: "",
      password: "",
      selectAll: false,
      rowBranchItem: [],
      program: [],
      formatted: "",
      selected2: "",
      accessBranchId: [],
      accessProgramId: [],
      showPassword: false,

      userform: {
        prefixId: "",
        name: "",
        familyName: "",
        gender: "",
        citizenId: "",
        birthDate: "",
        mobilePhone: "",
        email: "",
        buildingNumber: "",
        townshipNumber: "",
        townshipName: "",
        subLane: "",
        lane: "",
        streetName: "",
        districtId: { nameTh: "" },
        amphurId: { nameTh: "" },
        provinceId: { nameTh: "" },
        postCode: "",
        startWorkingDate: "",
        roleId: "",
        username: "",
        password: "",
        branchId: "",
      },
      submitforms: false,
      submitform: false,
    };
  },
  validations: {
    userform: {
      name: {
        required,
      },

      citizenId: {
        required,
        numeric,
        minLength: minLength(13),
        maxLength: maxLength(13),
      },
      mobilePhone: {
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      email: {
        required,
      },

      postCode: {
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },

      password: {
        minLength: minLength(8),
      },
    },
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    fullAddress: function () {
      return `${
        this.userform.buildingNumber != null
          ? "" + this.userform.buildingNumber
          : ""
      } ${
        this.userform.townshipNumber != null
          ? "หมู่" + " " + this.userform.townshipNumber
          : ""
      } ${
        this.userform.townshipName != null
          ? "" + this.userform.townshipName
          : ""
      } ${
        this.userform.subLane != null
          ? "ตรอก" + " " + this.userform.subLane
          : ""
      } ${this.userform.lane != null ? "ซอย" + " " + this.userform.lane : ""} ${
        this.userform.streetName != null
          ? "ถนน" + " " + this.userform.streetName
          : ""
      } ${
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined
          ? this.districtId.nameTh
          : ""
      } ${
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined
          ? this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId.nameTh != ""
          ? this.provinceId.nameTh
          : ""
      }  ${
        this.userform.postCode != null
          ? "รหัสไปรษณีย์" + " " + this.userform.postCode
          : ""
      }
      `;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const accessBranch = [];
    const accessProgram = [];
    user.userAccessBranch.forEach((item) => {
      accessBranch.push(item.branchId);
    });
    user.userAccessProgram.forEach((ele) => {
      accessProgram.push(ele.programId);
    });
    this.accessBranchId = accessBranch;
    this.accessProgramId = accessProgram;
  },
  created() {
    this.fixGender();
    this.getProgram();
    this.getPrefixes();
    // this.getBranch();
    this.getLocalData();
    this.getRole();
    this.getProvinces();
    this.getDataUpdate();
    this.startIndex = 1;
    this.endIndex = this.perPage;
    this.paginatedData = this.rowUser.slice(this.startIndex, this.endIndex);
  },
  watch: {
    accessProgramId(newValue) {
      if (newValue.length !== this.rowProgram.length) {
        this.selectAll = false;
      } else if (newValue.length === this.rowProgram.length) {
        this.selectAll = true;
      }
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    selectAlls() {
      this.accessProgramId = [];
      if (!this.selectAll) {
        for (let i in this.rowProgram) {
          this.accessProgramId.push(this.rowProgram[i].programId);
        }
      }
    },
    checkCitizenId: function () {
      if (this.userform.citizenId.substring(0, 1) === 0) return false; //substring ระหว่างตำแหน่ง 0, 1
      if (this.userform.citizenId.length != 13) return false;
      var sum = 0;
      var item = 0;
      for (item = 0, sum = 0; item < 12; item++) {
        sum += parseInt(this.userform.citizenId.charAt(item)) * (13 - item);
      }
      let mod = sum % 11;
      let check = (11 - mod) % 10;
      if (check == parseInt(this.userform.citizenId.charAt(12))) {
        return true;
      }
      return false;
    },

    fixGender(gId) {
      if (gId == "M") {
        return "ชาย";
      } else if (gId == "F") {
        return "หญิง";
      }
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.localDataProgram = user.userAccessProgram;

      const program = [];
      this.localDataProgram.forEach((item) => {
        program.push(item);
      });
      this.program = program;
      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;

      // console.log("", this.localDataBranchUser);
    },
    onFiltered(filteredItems) {
      this.rowUser = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowUser) {
      this.selected = rowUser;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    userForm() {
      this.submitform = true;
      this.submitforms = true;
      // var result = this.checkCitizenId();
      // this.checks = result;
      this.$v.$touch();
      if (this.$v.userform.$invalid != true) {
        // && this.checks != false
        this.updateUser();
      }
    },

    getBranch: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/branch", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowBranch = response.data.data;

          const arr = [];
          this.rowBranch.forEach((item) => {
            arr.push(item);
          });
          this.rowBranchItem = arr;
          // console.log(arr);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    // `${appConfig.api.baseURL}api/user/show`
    getDataUpdate: function () {
      this.overlayFlag = true;

      useNetw
        .get("api/profile", {})
        .then((response) => {
          this.rowUser = response.data.data;
          this.userform.name = response.data.data.name;
          this.userform.familyName = response.data.data.familyName;
          this.provinceId = {
            nameTh:
              response.data.data.provinceNameTh != null
                ? response.data.data.provinceNameTh
                : "",
          };
          this.amphurId = {
            nameTh:
              response.data.data.amphurNameTh != null
                ? response.data.data.amphurNameTh
                : "",
          };
          this.userform.buildingNumber = response.data.data.buildingNumber;
          this.userform.citizenId =
            response.data.data.citizenId != null
              ? response.data.data.citizenId
              : "";
          this.districtId = {
            nameTh:
              response.data.data.districtNameTh != null
                ? response.data.data.districtNameTh
                : "",
          };
          this.userform.birthDate = response.data.data.birthDate;
          this.userform.mobilePhone = response.data.data.mobilePhone;
          this.userform.startWorkingDate = response.data.data.startWorkingDate;
          this.userform.streetName = response.data.data.streetName;
          this.userform.subLane = response.data.data.subLane;
          this.userform.townshipName = response.data.data.townshipName;
          this.userform.townshipNumber = response.data.data.townshipNumber;
          this.userform.prefixId = {
            prefixId: response.data.data.prefixId,
            nameTh: response.data.data.prefixNameTh,
          };
          if (response.data.data.roleId != null) {
            this.userform.roleId = {
              roleId:
                response.data.data.roleId != null
                  ? response.data.data.roleId
                  : null,
              nameTh:
                response.data.data.roleNameTh != null
                  ? response.data.data.roleNameTh
                  : null,
            };
          }
          this.userform.branchId = {
            nameTh: response.data.data.branchNameTh,
            branchId: response.data.data.branchId,
          };
          this.userform.email = response.data.data.email;
          this.userform.fullAddress = response.data.data.fullAddress;
          this.userform.gender = {
            gender: response.data.data.gender,
            text: this.fixGender(response.data.data.gender),
          };
          this.userform.lane = response.data.data.lane;
          this.userform.postCode = response.data.data.postCode;
          this.accessBranchIdArr = response.data.data.userAccessBranch;
          this.accessProgramIdArr = response.data.data.userAccessProgram;

          // const accessBranch = [];
          // const accessProgram = [];
          // this.accessBranchIdArr.forEach((item) => {
          //   accessBranch.push(item.branchId);
          // });
          // this.accessProgramIdArr.forEach((item) => {
          //   accessProgram.push(item.programId);
          // });
          // this.accessBranchId = accessBranch;
          // this.accessProgramId = accessProgram;

          // if (this.accessProgramId.length !== this.rowProgram.length) {
          //   this.selectAll = false;
          // } else if (this.accessProgramId.length === this.rowProgram.length) {
          //   this.selectAll = true;
          // }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;

          // this.loading = false; //skeleton false
        });
    },

    updateUser: function () {
      // this.overlayFlag = true;

      useNetw
        .put("/api/profile/update", {
          // branchId: this.userform.branchId.branchId,
          userId: this.userId,
          // roleId: this.userform.roleId.roleId,
          name: this.userform.name != null ? this.userform.name : "",
          familyName:
            this.userform.familyName != null ? this.userform.familyName : "",
          provinceId:
            this.provinceId.provinceId != "" ? this.provinceId.provinceId : "",
          amphurId:
            this.amphurId.amphureId != "" ? this.amphurId.amphureId : "",
          buildingNumber:
            this.userform.buildingNumber != null
              ? this.userform.buildingNumber
              : "",
          citizenId: this.userform.citizenId,
          districtId:
            this.districtId.districtId != "" ? this.districtId.districtId : "",
          birthDate:
            this.userform.birthDate != null ? this.userform.birthDate : "",
          mobilePhone:
            this.userform.mobilePhone != null ? this.userform.mobilePhone : "",
          startWorkingDate:
            this.userform.startWorkingDate != null
              ? this.userform.startWorkingDate
              : "",
          streetName:
            this.userform.streetName != null ? this.userform.streetName : "",
          subLane: this.userform.subLane != null ? this.userform.subLane : "",
          townshipName:
            this.userform.townshipName != null
              ? this.userform.townshipName
              : "",
          townshipNumber:
            this.userform.townshipNumber != null
              ? this.userform.townshipNumber
              : "",
          prefixId:
            this.userform.prefixId.prefixId != null
              ? this.userform.prefixId.prefixId
              : "",
          email: this.userform.email != null ? this.userform.email : "",
          fullAddress: this.fullAddress != null ? this.fullAddress : "",
          gender: this.userform.gender.id,
          lane: this.userform.lane != null ? this.userform.lane : "",
          postCode:
            this.userform.postCode != null ? this.userform.postCode : "",
          password: this.userform.password,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "home" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    ChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    PageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onEdit(item) {
      if (item.isEditing) item.isEditing = false;
      else this.$set(item, "isEditing", true);
    },
    getProvinces: function () {
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
          // console.log(this.provinces);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getAmphures: function (isReset) {
      // this.overlayFlag = true;
      if (this.provinceId != "" && this.provinceId != undefined) {
        this.loading = true; //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.provinceId.provinceId,
            },
          })
          .then((response) => {
            this.amphures = response.data.data;
            // console.log(this.amphures);
            if (isReset) {
              this.amphurId = "";
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            // this.loading = false; //skeleton false
          });
      }
    },
    getDistricts: function (isReset) {
      // this.overlayFlag = true;
      if (this.provinceId != "" && this.provinceId != undefined) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.amphurId.amphureId,
            },
          })
          .then((response) => {
            this.districts = response.data.data;
            if (isReset) {
              this.districtId = "";
              // this.userform.amphurId = null
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
          });
      }
    },
    getProgram: function () {
      useNetw
        .get("api/master/programs", {})
        .then((response) => {
          this.rowProgram = response.data.data;

          const arr = [];
          this.rowProgram.forEach((item) => {
            arr.push(item);
          });
          this.rowProgramItem = arr;

          // console.log(this.rowProgramItem);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getPrefixes: function () {
      this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/master/person/prefixes", {})
        .then((response) => {
          this.prefixOPtion = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getRole: function () {
      this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/master/roles", {})
        .then((response) => {
          this.optionsRole = response.data.data;
          // console.log(this.optionsRole);
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    onReset() {
      // event.preventDefault();
      // Reset our form values
      this.userform.prefixId = [];
      this.userform.name = "";
      this.userform.familyName = "";
      this.userform.gender = [];
      this.userform.citizenId = "";
      this.userform.birthDate = "";
      this.userform.mobilePhone = "";
      this.userform.buildingNumber = "";
      this.userform.townshipNumber = "";
      this.userform.subLane = "";
      this.userform.lane = "";
      this.userform.streetName = "";
      this.provinceId = [];
      this.amphurId = [];
      this.districtId = [];
      this.userform.postCode = "";
      this.userform.fullAddress = "";
      this.userform.startWorkingDate = "";
      this.userform.roleId = [];
      this.userform.branchId = [];
      this.userform.email = "";
      this.userform.password = "";
      this.accessBranchId = [];
      this.accessProgramId = [];
    },
  },

  middleware: "authentication",
};
</script>
<style scoped>
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.input-placeholder {
  font-size: 13px;
}
.left-addon .glyphicon {
  left: 0px;
}
.right-addon .glyphicon {
  right: 4px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 10px;
}
</style>